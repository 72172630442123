<template>
  <div>
    <kn-form-subtitle title="Agregar categoría de egresos" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="expenseCategory.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de categorías de egreso"
      divider-position="bottom"
    />
    <v-list>
      <v-list-item-group>
        <template v-for="(item, index) in expenseCategoriesActive">
          <v-list-item :key="item.dato">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === expenseCategory.id"
                  class="mb-0 pb-0"
                  v-model="expenseCategory.dato"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  v-text="item.dato"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === expenseCategory.id"
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < expenseCategoriesActive.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue') 
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      expenseCategory: {
        id: 'NA', // No aplica
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      expenseCategories: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    expenseCategoriesActive() {
      return this.expenseCategories.filter(category => category.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.expenseCategory)
    }
  },
  async created() {
    this.expenseCategory.id_autor = this.userData.id
    this.expenseCategory.id_institucion_educativa = this.institutionId
    this.expenseCategories = await this.fetchExpenseCategoryByEI(this.institutionId)

  },
  methods: {
    async fetchExpenseCategoryByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/categoria-egreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener categorias de egresos por institucion educativa', error);
      }
    },
    async createExpenseCategory() {
      try {
        const response = await api.post('/administracion/crear-categoria-egreso', {
          nombre_categoria_egreso: this.expenseCategory.dato,
          id_autor: this.expenseCategory.id_autor,
          id_institucion_educativa: this.expenseCategory.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear el tipo de gasto.')
        }
      } catch (error) {
        console.error('Error al intentar crear categoria de egreso', error)
        this.errors.push('Error al intentar crear categoria de egreso')
      }
    },
    async updateExpenseCategory() {
      try {
        const response = await api.patch('/administracion/update-categoria-egreso', {
          id: this.expenseCategory.id,
          categoria_egreso: this.expenseCategory.dato,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar el tipo de gasto')
        }
      } catch (error) {
        console.error('Error al intentar actualizar categoria de egreso', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteExpenseCategory(categoryId) {
      try {
        const response = await api.post('/administracion/delete-categoria-egreso', {
          id_categoria_egreso: categoryId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar el tipo de gasto')
        }
      } catch (error) {
        console.error('Error al intentar eliminar categoria de egreso', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateExpenseCategory()
        this.toUpdate = false
      } else {
        await this.createExpenseCategory()
        this.clear()
      }
      this.expenseCategories = await this.fetchExpenseCategoryByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.expenseCategory = {
        id: 'NA',
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.expenseCategory = {
        id: 'NA',
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.expenseCategory = {...item}
      // console.log('Item a editar:', this.expenseCategory);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteExpenseCategory(itemId)
      this.expenseCategories = await this.fetchExpenseCategoryByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>